<template>
  <vx-card title="Salesmen Items">
    <vs-button
      class="mt-2"
      color="success"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      >Create</vs-button
    >
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span></span>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file"
          name="file"
          accept=".xls, .xlsx"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
          >Submit</vs-button
        >
        <!-- <vs-button
          class="ml-4 mt-2"
          type="border"
          color="warning"
          v-on:click="handleDownload"
          >Download Template</vs-button
        > -->
      </div>
    </div>
    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <data-table
            :territoryID="this.selectedTerritory.id"
            :baseUrl="this.baseUrl"
            :detail="this.detail"
          ></data-table>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTable,
    Datepicker,
  },
  data() {
    return {
      uploadReady: true,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/salesman-items",
      optionTerritory: [],
      selectedTerritory: {},
    };
  },
  methods: {
    handleSubmit() {
      var file = this.$refs.file.files[0];
      if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.formData = new FormData();
        this.formData.append("file", file);
        this.$vs.loading();
        this.$http
          .post(
            "/api/sfa/v1/salesman-items/import-salesman-items",
            this.formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "OK") {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.uploadReady = false;
              this.$nextTick(() => {
                this.uploadReady = true;
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((success) => {
            this.$vs.notify({
              color: "success",
              title: "success",
              text: success,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          });
      }
    },
    handleCreate() {
      this.$router.push({
        name: "salesman-items-create",
        // params: { id: id },
      });
    },

    handleOpen() {
      this.detail = true;
    },
  },
  mounted() {
    this.getTerritory();
  },
  watch: {
    selectedTerritory() {
      this.getZone();
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
